// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";
@import "map";
@import "toast";
@import "bootstrap";


// ie fixes
@import "ie-fix";

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 5px;
    margin-bottom: 5px;
}
.m-b {
    margin-bottom: 12px;
}
.fr{
	float:right;
}