.sidebar, .sidebar-minimized .sidebar .nav-link:hover{
    background: #fff;
}
.sidebar .nav-link:hover, .sidebar .nav-link {
    color: #3a4248;
}
.sidebar .nav-link:hover, .sidebar .nav-link.active {
    color: #fff !important;
    background: #3a4248 !important;
}
.sidebar .nav-link:hover, .sidebar .nav-link.active .nav-icon {
    color: #fff;
}
.sidebar .nav-dropdown.open .nav-link{
	color: #3a4248;
    background: #dadada;
}
.sidebar .nav-dropdown.open .nav-dropdown-items .nav-link{
    padding-left: 35px;
}
.sidebar .nav-dropdown.open {
    background: transparent;
}
.sidebar .nav{
    border-right: 1px solid #e4e5e6;
}
.nav-item{
    border-bottom: 1px solid #e4e5e6;
}
.navbar-brand img{
    max-height: 100%;
    margin-top: 0px;
}
.sidebar .sidebar-minimizer{
    background-color: rgb(58, 66, 72);
}

.sidebar .nav-dropdown-toggle::before{
    transform: rotate(-180deg)
}
.sidebar .nav-link .nav-icon{
    margin: 0 1.5rem 0 0;
    font-size: 1.5rem;
}
.card-header-action{
    cursor: pointer
}
.main{
    background-color: #dadada;
}
.main .container-fluid{
    padding: 0 20px 20px 20px;
}

[class*='col-']{
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.panel{
    margin-bottom: 0px !important;
    border: none !important;
}
.panel-heading{
    padding: 13px 15px !important;
}

.card{
    border-radius: 0;
    border: none !important;
    margin-bottom: 0 !important
}
.card-box{
    border-right: 1px solid #dadada !important;
}
.loading{   width: 100%;    height: 100%;    z-index: 9999;    position: fixed;    background: #FFF;    opacity: 0.7;}
.loader{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-app-box{
    border: 1px dashed #d4d8d9;
    color: #abaeb0;
    background: #f6f6f8;
    font-weight: bold;
    cursor: pointer
}

body{
    color: #3a4248;
}
.title{
    color: #3a4248;
    font-weight: bold;
}

.popover{
    max-width: 700px
}

.date-range-output{
    color: #2d353e;
    cursor: pointer;
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    zoom: 1;
        font-size: 13px;
    font-weight: 800;
    margin-top: 9px;
}
.date-range-output .icon-calendar{
        padding-right: 10px;
}
.date-range-output .desktop-only{
    padding-right: 10px;
}
.date-range-output .btn{
        padding: 0;
    background: none;
    border: none;
}

.DateRangePicker__CalendarSelection {
    background-color: #2cbcff;
    border: 1px solid #2cbcff;
}

.chart-wrapper{
    min-height: 300px
}
.display-4{
    font-size: 2rem
}

.custom-listing{
    border-radius: 0px;
    overflow: hidden;
    max-height: 175px;
    overflow-y: auto;
    position:relative;
}
.custom-listing li{
    padding:2px 10px;
    border-radius: 0px;
}
.list-group-item.bdr-none:first-child{
    border:none; 
}

.custom-user-listing li span{
    display:block
}
.custom-user-listing li span:first-child{
    width:50%;
}
.custom-user-listing li span:nth-child(2){
    width:20%;
}

.no-underline, .no-underline:hover{
    text-decoration: none;
}

.btn-outline-primary{
    border-color: #20a8d8 !important;
}
.btn-outline-primary:hover{
    color: #ffffff !important;
}
.btn-outline-primary:focus{
    outline: none !important;
}
.validation-error {
    padding: 4px;
    color: #d5563b;
    font-size: 13px;
}
.search-icon{
    position: absolute;
    top: 2px;
    right: 20px;
    height: 30px;
    width: 20px !important;
    cursor: pointer;
}
.copy-icon{
    border: 1px solid #20a8d8;
    padding: 2px;
    margin-left: 6px;
    border-radius: 2px;
    color: #20a8d8;
    cursor: pointer;
}
.pointer{
    cursor: pointer;   
}

.dash-percentage-quote{
    font-size: 14px;
}

.sdk-cards{
    min-height: 181px;
}
.skd-cards-spacer {
    padding: 10px;
}
